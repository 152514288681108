<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12 pt-0 pb-2">
        <h1>All Personnel</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12 pt-0 pb-0">
        <router-link to="/admin/personnel-add">
          <v-btn x-small>
            Add new person
          </v-btn>
        </router-link>
        <router-link to="/admin/personnel">
          <v-btn x-small>
            View current
          </v-btn>
        </router-link>
        <router-link to="/admin/personnel-search">
          <v-btn x-small>
            Search
          </v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-simple-table
          fixed-header
          height="450px"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Is crew member?</th>
                <th class="text-left">Is team leader?</th>
                <th class="text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(person, index) in personnel"
                :key="index"
              >
                <td>{{ person.name }}</td>
                <td>
                  <span v-if="person.isCrewMember">Yes</span>
                  <span v-else>No</span>
                </td>
                <td>
                  <span v-if="person.isTeamLeader">Yes</span>
                  <span v-else>No</span>
                </td>
                <td>
                  <router-link custom v-slot="{ navigate }" :to="`/admin/personnel-view/${person.id}`">
                    <v-btn
                      x-small
                      @click="navigate"
                      @keypress.enter="navigate"
                      role="link">
                      View
                    </v-btn>
                  </router-link>
                  &nbsp;
                  <router-link custom v-slot="{ navigate }" :to="`/admin/personnel-edit/${person.id}`">
                    <v-btn
                      x-small
                      @click="navigate"
                      @keypress.enter="navigate"
                      role="link">
                      Edit
                    </v-btn>
                  </router-link>
                  &nbsp;
                  <v-btn
                    x-small
                    @click="unArchivePerson(index)">
                    Un-archive
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-pagination
          class="pt-4"
          v-model="page"
          :length="length"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          :total-visible="totalVisible"
        ></v-pagination>
      </v-col>
    </v-row>
    <v-dialog
      v-model="warnUnArchive"
      max-width="290"
    >
      <v-card>
        <v-card-text class="pt-4">
          Are you sure you want to un-archive this person?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="confirmUnArchive"
          >
            Confirm
          </v-btn>
          <v-btn
            text
            @click="warnUnArchive = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from '../../../axios';

export default {
  name: 'AdminPersonnelIndex',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      personnel: [],
      page: 1,
      length: 0,
      totalVisible: 9,
      warnUnArchive: false,
      toUnArchive: -1,
    };
  },
  watch: {
    page() {
      this.getPersonnel();
    },
  },
  methods: {
    unArchivePerson(index) {
      this.toUnArchive = index;
      this.warnUnArchive = true;
    },
    confirmUnArchive() {
      const personId = this.personnel[this.toUnArchive].id;
      axios.get(`/personnel/unArchive/${personId}.json?token=${this.token}`)
        .then(() => {
          this.personnel.splice(this.toArchive, 1);
          this.warnUnArchive = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPersonnel() {
      axios.get(`/personnel/getAll/${this.page}.json?token=${this.token}&archived=1`)
        .then((response) => {
          this.personnel = response.data.personnel;
          this.length = response.data.length;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getPersonnel();
  },
};
</script>
